/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage;
    Sage = {

        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages


            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired

                $('.thumbnail-image-1 a').click(function (event) {
                    event.preventDefault();
                    $('#imageBox-1').empty().append(
                        ($("<img>", {src: this.href}).addClass('img-fluid image-box'))
                    );
                });

                $('.thumbnail-image-2 a').click(function (event) {
                    event.preventDefault();
                    $('#imageBox-2').empty().append(
                        ($("<img>", {src: this.href}).addClass('img-fluid image-box'))
                    );
                });

                $('.thumbnail-image-3 a').click(function (event) {
                    event.preventDefault();
                    $('#imageBox-3').empty().append(
                        ($("<img>", {src: this.href}).addClass('img-fluid image-box'))
                    );
                });

                $('.thumbnail-image-4 a').click(function (event) {
                    event.preventDefault();
                    $('#imageBox-4').empty().append(
                        ($("<img>", {src: this.href}).addClass('img-fluid image-box'))
                    );
                });

                $('.thumbnail-image-5 a').click(function (event) {
                    event.preventDefault();
                    $('#imageBox-5').empty().append(
                        ($("<img>", {src: this.href}).addClass('img-fluid image-box'))
                    );
                });


                // If hover on .dropdown add .highlight to parent menu item
                var win = $(window);
                $(document).ready(function () {
                    if (win.width() >= 768) {
                        $(".dropdown").hover(
                            function () {
                                $('.dropdown-menu', this).stop(true, true).slideDown("fast");
                                $(this).toggleClass('highlight');
                            },
                            function () {
                                $('.dropdown-menu', this).stop(true, true).slideUp("fast");
                                $(this).toggleClass('highlight');
                            }
                        );
                    } else {
                    }
                });



                    // Navbar color change
                    var navPosition = $('#site-intro').offset();
                    var navbarArea = $('#primary-navigation');


                    $(document).scroll(function () {
                        var y = $(this).scrollTop();
                        if (y >= navPosition.top) {
                            navbarArea.addClass('nav-bg-scroll');
                            $('.slogan').css({"display": "none"});
                        } else {
                            navbarArea.removeClass('nav-bg-scroll');
                            $('.slogan').css({"display": "inline"});
                        }
                    });

                    // Footer fixed on bottom when window < screen

                    $(document).ready(function () {
                        if ($(window).height() >= $(document).height()) {
                            $('#footer').addClass('fixed-footer');
                        } else {
                            $('#footer').removeClass('fixed-footer');
                        }
                    });

                    $('#courseBtn').click(function () {
                        $('#courseList').toggleClass('show');
                        $('#courseBtn').find('i').toggleClass(' fa-chevron-right  fa-chevron-down');
                    });

              // spam protection

              function spamProtect(email) {
                event.preventDefault();
                window.location = 'mailto:' + email;
              };


              $('#infoAt').click(function () {
                var email = 'info@mbsr-yoga-berlin.de';
                spamProtect(email);
              });


                }
            },
            // Home page
            'home': {
                init: function () {
                    // JavaScript to be fired on the home page
                },
                finalize: function () {
                    // JavaScript to be fired on the home page, after the init JS

                    $(window).ready(function () {
                        var courseTable = $("#course-table");

                        if ($(window).width() < 992) {
                            courseTable.addClass("table-responsive");
                        } else {
                            courseTable.removeClass("table-responsive");
                        }

                    });

                }
            },
            // Fire those code on all single project pages
            'page_template_template_offers_detail': {
                init: function () {
                    // JavaScript to be fired on all pages


                },
                finalize: function () {
                    // JavaScript to be fired on all single project template pages, after page specific JS is fired

                    // Fadein/out Toc button based on window position
                    var backToToc = $('#toc-btn');
                    $(document).scroll(function () {
                        var y = $(this).scrollTop();
                        if (y >= $(window).height()) {
                            backToToc.fadeIn();
                        } else {
                            backToToc.fadeOut();
                        }
                    });

                    // When the user clicks on the back-to-toc-button, scroll back to toc
                    $("#toc-btn").click(function () {
                        $('html, body').animate({
                            scrollTop: $("#toc").offset().top
                        }, 400);
                    });


                    // Add smooth scrolling to all links
                    $(document).ready(function () {
                        $(".animated-link").on('click', function (event) {

                            // Make sure this.hash has a value before overriding default behavior
                            if (this.hash !== "") {
                                // Prevent default anchor click behavior
                                event.preventDefault();

                                // Store hash
                                var hash = this.hash;

                                $('html, body').animate({
                                    scrollTop: $(hash).offset().top
                                }, 400, function () {

                                    // Add hash (#) to URL when done scrolling (default click behavior)
                                    window.location.hash = hash;
                                });
                            } // End if
                        });
                    });


                } // End finalize function
            }, // End page specific function

            // Fire this code on all registration pages
            'page_template_template_registration_detail': {
            init: function () {
                // JavaScript to be fired on all pages

            },
            finalize: function () {
                // JavaScript to be fired on all registration pages, after page specific JS is fired
                $(document).ready(function () {
                    $('#course-request').val($('#course-name').text());
                    $('#course-request').prop('readonly', true);
                });

            } // End finalize function
        }, // End page specific function

        }; // End of DOM-based routing function

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
